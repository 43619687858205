import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import LoaderIcon from "src/components/common/icons/LoaderIcon";
import { useLanguage } from "src/components/language/LanguageController";
import useLiterBtDevice from "src/hooks/literBtDevice/useLiterBtDevice";
import {
  selectDevice,
  selectIsBtOn,
  selectIsConnected,
  selectIsConnecting,
} from "src/store/liter-bt-device/selectors";

const LiterAutomationDevice = () => {
  const { t } = useLanguage();
  const { connectDevices, disconnectDevices, syncConfiguration } =
    useLiterBtDevice();
  const isLiterBtDeviceConnected = useSelector(selectIsConnected);
  const isBtOn = useSelector(selectIsBtOn);
  const btDeviceDetails = useSelector(selectDevice);
  const isConnecting = useSelector(selectIsConnecting);

  return (
    <div className="row border-bottom pb-2 mb-2">
      <div className="col">
        <div className="form-row">
          <div className="col">
            <h4>{t("text_liter_device")}</h4>
          </div>
        </div>
        <div className="form-row">
          <div className="col-8 d-inline-flex align-items-center">
            <label className="mr-1 mb-0">{t("text_bluetooth")}: </label>{" "}
            {isBtOn ? "On" : "Off"}
            <label className="mr-2 ml-2 mb-0">
              {t("text_device_name")}:{" "}
            </label>{" "}
            {btDeviceDetails.deviceName}
            <label className="mr-2 ml-2 mb-0">
              {t("text_device_status")}:{" "}
            </label>{" "}
            {isLiterBtDeviceConnected ? "Connected" : "Disconnected"}
          </div>
          <div className="col-4 text-right">
            {!isLiterBtDeviceConnected ? (
              <PrimaryButton
                startIcon={<LoaderIcon spin={isConnecting} />}
                onClick={connectDevices}
                disabled={isConnecting || isLiterBtDeviceConnected}
              >
                {t("text_connect")}
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton onClick={() => syncConfiguration()}>
                  {t("text_sync_configuration")}
                </PrimaryButton>
                <PrimaryButton
                  startIcon={<LoaderIcon spin={isConnecting} />}
                  onClick={disconnectDevices}
                  className="ml-2"
                >
                  {t("text_disconnect")}
                </PrimaryButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiterAutomationDevice;
