import useHardwareDeviceEvents from "src/hooks/hardwareIntegration/useHardwareDeviceEvents";
import useLiterBtDeviceEvents from "src/hooks/literBtDevice/useLiterBtDeviceEvents/useLiterBtDeviceEvents";
import useAutoCollectionEvent from "src/hooks/useAutoCollectionEvent";
import useDesktopIntegration from "src/hooks/useDesktopIntegration";

const DesktopIntegration = () => {

    useDesktopIntegration();
    useLiterBtDeviceEvents();
    useHardwareDeviceEvents();
    useAutoCollectionEvent();

    return null;
}

export default DesktopIntegration;