import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isConnected: false,
  isConnecting: false,
  isBtOn: false,
  device: {},
  error: null
}

export const literBtDeviceSlice = createSlice({
  name: 'literBtDevice',
  initialState,
  reducers: {
    updateIsConnecting: (state, { payload }) => {
      state.isConnecting = payload;
    },
    updateDevice: (state, { payload }) => {
      state.isConnecting = false;
      state.device = payload;
    },
    updateDeviceStatus: (state, { payload }) => {
      state.isConnecting = false;
      state.isConnected = payload;
    },
    updateBtStatus: (state, { payload }) => {
      state.isBtOn = payload;
    }
  },
  extraReducers: (builder) => {
  }
});

export const { updateIsConnecting, updateDevice, updateDeviceStatus, updateBtStatus } = literBtDeviceSlice.actions

export default literBtDeviceSlice.reducer;