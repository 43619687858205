// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { fetchMilkCollection } from "./thunk";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  isQueueFetched: false,
};

export const milkCollectionSlice = createSlice({
  name: "milkCollection",
  initialState,
  reducers: {
    addItem: (state, { payload }) => {
      const { shift, liter, selectedCustomer, selectedCattle } = payload;
      const items = [...state.data];
      const isExists = items.some(
        (item) =>
          item.customer.dairy_customer_id ===
            selectedCustomer.dairy_customer_id && item.cattle === selectedCattle
      );
      if (!isExists) {
        items.unshift({
          customer: selectedCustomer,
          analysis: false,
          updateQueue: true,
          cattle: selectedCattle,
          milk: {
            liter,
            shift,
            fat: null,
            clr: null,
            snf: null,
            rate: null,
            total: null,
          },
        });
      }
      state.data = items;
    },
    updateItemAnalysis: (state, { payload }) => {
      const { milkAnalysis } = payload;
      const items = [...state.data];
      let isAnalysisSet = false;
      state.data = items
        .reverse()
        .map((item) => {
          if (!item.analysis && !isAnalysisSet) {
            console.log("updateItemAnalysis::milkAnalysis", milkAnalysis);
            item.milk = { ...item.milk, ...milkAnalysis };
            item.analysis = true;
            item.updateQueue = true;
            isAnalysisSet = true;
          }
          return item;
        })
        .reverse();
    },
    updateItem: (state, { payload }) => {
      const { liter, fat, clr, snf, rate, total, dairy_customer_id, cattle } =
        payload;
      const items = [...state.data];
      const itemIndex = items.findIndex(
        (item) =>
          item.customer.dairy_customer_id === dairy_customer_id &&
          item.cattle === cattle
      );
      if (itemIndex > -1) {
        items[itemIndex].updateQueue = false;
        items[itemIndex].analysis = fat > 0;
        items[itemIndex].milk.liter = liter;
        items[itemIndex].milk.fat = fat;
        items[itemIndex].milk.clr = clr;
        items[itemIndex].milk.snf = snf;
        items[itemIndex].milk.rate = rate;
        items[itemIndex].milk.total = total;
      }
      state.data = items;
    },
    removeItem: (state, { payload }) => {
      const { rowIndex } = payload;
      state.data = state.data.filter((item, index) => index !== rowIndex);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMilkCollection.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMilkCollection.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload ? [...action.payload] : [];
      state.error = null;
      state.isQueueFetched = true;
    });

    builder.addCase(fetchMilkCollection.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message || null;
    });
  },
});

export const { addItem, updateItem, updateItemAnalysis, removeItem } =
  milkCollectionSlice.actions;

export default milkCollectionSlice.reducer;
