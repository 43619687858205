import React, { createContext, useContext, useState } from "react";
import useAutomaticSetting from "src/hooks/useAutomaticSetting";

const AutoCollectionContext = createContext({});

const AutoCollectionProvider = ({ children }) => {
    const [milkWeightRawData, setMilkWeightRawData] = useState("");
    const [milkAnalysisRawData, setMilkAnalysisRawData] = useState("");

    const value = {
        milkWeightRawData,
        setMilkWeightRawData,
        milkAnalysisRawData,
        setMilkAnalysisRawData,
    };

    return (
        <AutoCollectionContext.Provider value={value}>
            {children}
        </AutoCollectionContext.Provider>
    );
};

const useAutoCollection = () => useContext(AutoCollectionContext);

export { AutoCollectionProvider, useAutoCollection };
