// @ts-nocheck
import { Avatar, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { logoutAll, logoutUser } from 'src/actions/user';
import addCenterIcon from "src/assets/icons/sidebar_nav/addCenterIcon.png";
import { getDairyCount } from 'src/reducers/dairy';
import { toggleLanguagePopup, toggleSideBarClose } from '../../actions';
import blogIcon from "../../assets/icons/blog.png";
import gestationIcon from "../../assets/icons/cattle_gestation.png";
import changePasswordIcon from "../../assets/icons/change_password.png";
import homeIcon from "../../assets/icons/home.png";
import changeLanguageIcon from "../../assets/icons/language.png";
import logoutIcon from "../../assets/icons/logout.png";
import logoutAllIcon from "../../assets/icons/logout_all.png";
import profileIcon from "../../assets/icons/profile.png";
import rewardIcon from "../../assets/icons/reward.png";
import subscriptionIcon from "../../assets/icons/subscription.png";
import contactUsIcon from "../../assets/icons/support.png";
import withLanguage from '../language/LanguageController';
import ListItem1 from './ListItem';
import DairyNameWithSelection from "./Sidebar/DairyNameWithSelection";

const useStyles = makeStyles({
    list: {
      width: "100%",
    }
});

const SideBar = (props) => {
    const { t, user = {} } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const dairyCount = useSelector(getDairyCount);
    const customerGroupId = user.customer_group_id; 

    const showAddCenter = customerGroupId !=  1;

    return (
        <div
            className={`${classes.list} side-menu`}
            role="presentation"
            onClick={() => dispatch(toggleSideBarClose())}
            onKeyDown={() => dispatch(toggleSideBarClose())}
        >
            <List className="sidebar-navigation" disablePadding>
                <ListItem1 className="user-section-list">
                    <div className="center list-item__center list-item--material__center">
                        <div className="sidemenu-user clearfix">
                            <div className="user-avtar text-center">
                                <div className="user-avtar-box border rounded-circle border-secondary">
                                    <Avatar src={user.profile_pic} className="sidebar-user-avtar">
                                        <AccountCircle fontSize="large"/>
                                    </Avatar>
                                </div>
                            </div>
                            <div className="user-detail text-center">
                                <div className="user-name">
                                    {user.firstname} {user.lastname}
                                </div>
                                <div className="user-mobile">
                                    {dairyCount > 1 && user.customer_group_id === "2" && <DairyNameWithSelection />}
                                    {dairyCount <= 1 && <>{user.telephone} ({user.customer_group_id === "2" ? t("text_dairy_owner") : t("text_kisan")})</>}
                                </div>
                            </div>
                        </div>
                    </div>
                </ListItem1>
                <ListItem>
                    <ListItemIcon>
                        <img src={homeIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("text_home")} />
                    <Link to="/" className="stretched-link text-dark"/>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <img src={profileIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("text_profile")} />
                    <Link to="/account/profile" className="stretched-link text-dark"/>
                </ListItem>
                <Divider variant="inset" component="li" />
                {showAddCenter &&
                <ListItem>
                    <ListItemIcon>
                        <img src={addCenterIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("text_add_center")} />
                    <Link to="/dairy/add" className="stretched-link text-dark"/>
                </ListItem>}
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <img src={gestationIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("gestation_text")} />
                    <Link to="/gestation" className="stretched-link text-dark"/>
                </ListItem>
                <Divider variant="inset" component="li" />
                {user.customer_group_id == 2 ? (
                    <>
                        <ListItem>
                            <ListItemIcon>
                                <img src={subscriptionIcon} alt="" className="max-w-25"/>
                            </ListItemIcon>
                            <ListItemText primary={t("text_subscription")} />
                            <Link to="/subscription" className="stretched-link text-dark"/>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </>
                ) : null}

                <ListItem>
                    <ListItemIcon>
                        <img src={rewardIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("text_reward")} />
                    <Link to="/rewards" className="stretched-link text-dark"/>
                    </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <img src={blogIcon} alt="" className="max-w-25" />
                    </ListItemIcon>
                    <ListItemText primary={t("text_blog")} />
                    <a href="https://blog.liter.live" className="stretched-link text-dark" target="_blank" rel="noopener noreferrer">
                    </a>
                </ListItem>
                <Divider variant="inset" component="li" />

                <ListItem>
                    <ListItemIcon>
                        <img src={changeLanguageIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("text_choose_language")} />
                    <Link to="/account/changeLanguage" className="stretched-link text-dark" onClick={(e) => {
                            e.preventDefault();
                            dispatch(toggleLanguagePopup(true))
                    }}/>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <img src={changePasswordIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("text_change_password")} />
                    <Link to="/account/changePassword" className="stretched-link text-dark"/>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <img src={contactUsIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("text_contact_us")} />
                    <Link to="/contact" className="stretched-link text-dark"/>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <img src={logoutIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("text_logout")} />
                    <Link to="/logout" className="stretched-link text-dark" onClick={logoutUser}/>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <img src={logoutAllIcon} alt="" className="max-w-25"/>
                    </ListItemIcon>
                    <ListItemText primary={t("text_logout_from_all_device")} />
                    <Link to="/logoutAll" className="stretched-link text-dark" onClick={logoutAll}/>
                </ListItem>
            </List>
        </div>
    );
}

const mapStateToProps = state => {
    const { defaultReducer: { language }, userReducer: { user = {} } } = state;
    return {
        user: (user) ? user : {},
        language
    };
};

export default connect(mapStateToProps)(withLanguage(SideBar));