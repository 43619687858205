import { createSelector } from "@reduxjs/toolkit";
import { selectOfflineStore } from "../selectors";

export const selectMilkCollectionState = createSelector(
    selectOfflineStore,
    (state) => state.milkCollection
);

export const selectMilkCollection = createSelector(
    selectMilkCollectionState,
    (state) => state.data
);

export const selectIsFetched = createSelector(
    selectMilkCollectionState,
    (state) => state.isFetched
);

export const selectItemToUpdateMilkAnalysis = createSelector(
    selectMilkCollection,
    (state) => state.reverse().find(({ analysis }) => !analysis)
);