// @ts-nocheck
import axios from "axios";
import config from "../../../config";
import { getDeviceId, getToken } from "../util";
import UserModel from "../../models/User";
import * as Sentry from "@sentry/react";
import store from "../../../store";
import { togglePremiumPopup } from "src/actions";
import { t } from "src/components/language/LanguageController";

const shouldIntercept = (error) => {
  try {
    return error.response.status === 401;
  } catch (e) {
    return false;
  }
};

const handleHttpErrorResponse = (error) => {
  const { response } = error;
  if (response && response.status >= 400 && response.status <= 499) {
    const responseData = response.data;
    if (responseData.error_code === 501) {
      store.dispatch(
        togglePremiumPopup({
          feature: responseData.data.feature,
          message: responseData.message || "",
        })
      );
    }
    return responseData.message;
  } else if (response && response.status >= 500) {
    Sentry.captureException(error);
    return t("error_unknown_error");
  } else {
    Sentry.captureException(error);
    return t("error_unknown_error");
  }
};

const handleTokenRefresh = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: config.base_url + "v1/auth/refresh",
      method: "POST",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-DEVICE-ID": getDeviceId(),
      },
    })
      .then(({ data }) => {
        UserModel.update({
          auth_token: data.data.auth_token,
        });
        resolve(true);
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          new UserModel().logout();
          window.location.reload(true);
        }
        reject(err);
      });
  });
};

const attachTokenToRequest = (request) => {
  request.headers["Authorization"] = "Bearer " + getToken();
};

const axiosIntercepter = (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    shouldIntercept,
    ...customOptions,
  };

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve();
      }
    });

    failedQueue = [];
  };

  const interceptor = (error) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(handleHttpErrorResponse(error));
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(handleHttpErrorResponse(error));
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then(() => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest);
          return axiosClient.request(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then(() => {
          options.attachTokenToRequest(originalRequest);
          processQueue(null);
          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};

export default axiosIntercepter;
