// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Eventer from "src/components/library/Eventer";
import { selectWeightAnalyser, selectMilkAnalyser, selectHaveLiterDevice } from "src/store/automation-settings/selectors";
import HardwareDevice from "./HardwareDevice/HardwareDevice";
import { updateMilkAnalyserBaudRate, updateMilkAnalyserPath, updateWeightAnalyserBaudRate, updateWeightAnalyserPath } from "src/store/automation-settings/automation-settings";
import useHardwareDevice from "src/hooks/hardwareIntegration/useHardwareDevice";
import { selectMilkAnalyserStatus, selectWeightAnalyserStatus } from "src/store/hardware-devices/selectors";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import LoaderIcon from 'src/components/common/icons/LoaderIcon';
import { useLanguage } from "src/components/language/LanguageController";

const HardwareDevicesSetting = () => {
    const { t } = useLanguage();
    const dispatch = useDispatch();  
    const [milkWeightRawData, setMilkWeightRawData] = useState("");
    const [milkAnalysisRawData, setMilkAnalysisRawData] = useState("");
    const { weightAnalyserPath, weightAnalyserBaudRate } = useSelector(selectWeightAnalyser);
    const { milkAnalyserPath, milkAnalyserBaudRate } = useSelector(selectMilkAnalyser);
    const { connectedPorts, fetchDevicesStatus, isPortLoading, reloadSerialDevices } = useHardwareDevice({ setUpEvent: true });
    const weightAnalyserStatus = useSelector(selectWeightAnalyserStatus);
    const milkAnalyserStatus = useSelector(selectMilkAnalyserStatus);
    const haveLiterDevice = useSelector(selectHaveLiterDevice);


    const onMilkWeightRawData = ({ detail }) => {
        console.log("onMilkweightRawData", detail);
        setMilkWeightRawData(detail);
    }

    const onMilkAnalysisRawData = ({ detail }) => {
        console.log("onMilkAnalysisRawData", detail);
        setMilkAnalysisRawData(detail);
    }

    useEffect(() => {
        Eventer.on("milkWeightRawData", onMilkWeightRawData);
        Eventer.on("milkAnalysisRawData", onMilkAnalysisRawData);

        return () => {
            Eventer.off("milkWeightRawData", onMilkWeightRawData);
            Eventer.off("milkAnalysisRawData", onMilkAnalysisRawData);
        }
    }, []);

    useEffect(() => {
        fetchDevicesStatus();
    }, [fetchDevicesStatus]);

    return (
        <div className="row border-bottom pb-3 mb-2">
            <div className="col-6">
                <HardwareDevice
                    deviceName="weight_machine"
                    devicePath={weightAnalyserPath}
                    setDevicePath={(path) => dispatch(updateWeightAnalyserPath(path))}
                    deviceBaudRate={weightAnalyserBaudRate}
                    setDeviceBaudRate={(baudRate) => dispatch(updateWeightAnalyserBaudRate(baudRate))}
                    availableDevices={connectedPorts}
                    devicesStatus={weightAnalyserStatus}
                    deviceRawData={milkWeightRawData}
                    otherSelecteds={[milkAnalyserPath]}
                />
            </div>
            <div className="col-6">
                <HardwareDevice
                    deviceName="milk_analyser"
                    devicePath={milkAnalyserPath}
                    setDevicePath={(path) => dispatch(updateMilkAnalyserPath(path))}
                    deviceBaudRate={milkAnalyserBaudRate}
                    setDeviceBaudRate={(baudRate) => dispatch(updateMilkAnalyserBaudRate(baudRate))}
                    availableDevices={connectedPorts}
                    devicesStatus={milkAnalyserStatus}
                    deviceRawData={milkAnalysisRawData}
                    otherSelecteds={[weightAnalyserPath]}
                />
            </div>
            {!haveLiterDevice && <div className="col text-right">
                <PrimaryButton
                    className='mr-1'
                    startIcon={<LoaderIcon spin={isPortLoading} />}
                    onClick={reloadSerialDevices}
                    disabled={isPortLoading}
                >
                    {t("text_refresh_devices")}
                </PrimaryButton>
            </div>}
        </div>
    );
}

export default HardwareDevicesSetting;