// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit'
import { fetchMilkCollectionTotal } from "./thunk";

const initialState = {
  data: {
    count: 0,
    data: {}
  },
  isLoading: false,
  error: null
}

export const milkCollectionTotalSlice = createSlice({
  name: 'milkCollectionTotal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMilkCollectionTotal.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMilkCollectionTotal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = {
        count: action.payload?.count || 0,
        data: action.payload?.data || 0
      };
      state.error = null;
    });

    builder.addCase(fetchMilkCollectionTotal.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message || null;
    });
  }
})


export default milkCollectionTotalSlice.reducer;