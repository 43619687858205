// @ts-nocheck
import React from 'react';
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from "@material-ui/core";
import { useAutoCollection } from 'src/contexts/autoCollectionContext/AutoCollectionContext';
import { RadioPrimary } from "src/components/core/Radio";
import { PrimaryButton } from 'src/components/common/buttons/PrimaryButton';
import LoaderIcon from 'src/components/common/icons/LoaderIcon';
import { useLanguage } from 'src/components/language/LanguageController';
import "./AutoCollectionDevice.css";
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveLiterDevice } from 'src/store/automation-settings/automation-settings';
import { selectHaveLiterDevice } from 'src/store/automation-settings/selectors';
import useHardwareDevice from 'src/hooks/hardwareIntegration/useHardwareDevice';

const AutoCollectionDevice = () => {
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const haveLiterDevice = useSelector(selectHaveLiterDevice);

    return (
        <div className="row border-bottom pb-2 mb-2">
            <div className="col">
                <FormControl component="fieldset" className="form-control-inline">
                    <FormLabel component={"subtitle2"} className="mr-3">Do you have Liter Automation Device</FormLabel>
                    <RadioGroup row value={haveLiterDevice ? "1" : "0"} onChange={(event) => dispatch(updateHaveLiterDevice(event.target.value === "1"))}>
                        <FormControlLabel value="1" control={<RadioPrimary />} label="Yes" />
                        <FormControlLabel value="0" control={<RadioPrimary />} label="No" />
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
}

export default AutoCollectionDevice;