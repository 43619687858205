// @ts-nocheck
import { useSelector } from "react-redux";
import { selectHaveLiterDevice } from "src/store/automation-settings/selectors";
import { selectIsDevicesConnected as selectIsHardwareDevicesConnected } from "src/store/hardware-devices/selectors";
import { selectIsConnected as selectIsBtConnected } from "src/store/liter-bt-device/selectors";
import useLiterBtDevice from "../literBtDevice/useLiterBtDevice";
import useHardwareDevice from "../hardwareIntegration/useHardwareDevice";
import { useCallback } from "react";

const useAutomation = () => {
  const haveLiterDevice = useSelector(selectHaveLiterDevice);
  const isHardwareDevicesConnected = useSelector(
    selectIsHardwareDevicesConnected
  );
  const isBtDeviceConnected = useSelector(selectIsBtConnected);
  const btDevice = useLiterBtDevice();
  const hwDevice = useHardwareDevice();

  const isDevicesConnected = haveLiterDevice
    ? isBtDeviceConnected
    : isHardwareDevicesConnected;
  const autoDevice = haveLiterDevice ? btDevice : hwDevice;

  const updateAutoCollectionConfig = useCallback(() => {
    window?.literAPI?.hwDevices?.updateDeviceConfiguration();
    window?.literAPI?.bluetooth?.syncConfiguration();
  }, []);

  const dispatchGetMilk = useCallback(() => {
    autoDevice.dispatchGetMilk();
  }, [autoDevice]);

  const connectDevices = useCallback(() => {
    console.log("connectDevices");
    autoDevice.connectDevices();
  }, [autoDevice]);

  const disconnectDevices = useCallback(() => {
    autoDevice.disconnectDevices();
  }, [autoDevice]);

  const fetchDevicesStatus = useCallback(() => {
    autoDevice.fetchDevicesStatus();
  }, [autoDevice]);

  return {
    isDevicesConnected,
    updateAutoCollectionConfig,
    connectDevices,
    disconnectDevices,
    fetchDevicesStatus,
    dispatchGetMilk,
  };
};

export default useAutomation;
