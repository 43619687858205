import { createSelector } from "@reduxjs/toolkit";

export const selectHardwareDevicesStore = (state) => state.hardwareDevices;

export const selectMilkAnalyserStatus = createSelector(
    selectHardwareDevicesStore,
    (state) => state.milkAnalyser
);

export const selectWeightAnalyserStatus = createSelector(
    selectHardwareDevicesStore,
    (state) => state.weightAnalyser
);

export const selectDevicesStatus = createSelector(
    selectHardwareDevicesStore,
    (state) => state
);

export const selectIsMilkAnalyserConnected = createSelector(
    selectMilkAnalyserStatus,
    (state) => state.isConnected
);

export const selectIsWeightAnalyserConnected = createSelector(
    selectWeightAnalyserStatus,
    (state) => state.isConnected
);

export const selectIsDevicesConnected = createSelector(
    selectIsMilkAnalyserConnected,
    selectIsWeightAnalyserConnected,
    (milkAnalyser, weightAnalyser) => milkAnalyser && weightAnalyser
);
