// @ts-nocheck
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import Eventer from "src/components/library/Eventer";
import { updateBtStatus, updateDevice, updateDeviceStatus, updateIsConnecting } from "src/store/liter-bt-device/liter-bt-device";

const useLiterBtDeviceEvents = () => {
    const dispatch = useDispatch();
    
    const onDeviceConnected = useCallback(({ detail }) => {
        console.log("deviceConnected", detail);
        dispatch(updateDevice(detail));
        dispatch(updateDeviceStatus(true));
        dispatch(updateIsConnecting(false));
    }, [dispatch]);

    const onDeviceDisconnected  = useCallback(() => {
        dispatch(updateDeviceStatus(false));
    }, [dispatch]);

    const onDeviceStatus = useCallback(({ detail }) => {
        console.log("onDeviceStatus", detail);
        const { connected, btStatus, ...restDetail } = detail;
        dispatch(updateDevice(restDetail));
        dispatch(updateDeviceStatus(connected));
        dispatch(updateBtStatus(btStatus));
    }, [dispatch]);

    useEffect(() => {
        Eventer.on("BTDeviceConnected", onDeviceConnected);
        Eventer.on("BTDeviceStatus", onDeviceStatus);
        Eventer.on("BTDeviceDisconnected", onDeviceDisconnected);

        return () => {
            Eventer.off("BTDeviceConnected", onDeviceConnected);
            Eventer.off("BTDeviceStatus", onDeviceStatus);
            Eventer.off("BTDeviceDisconnected", onDeviceDisconnected);
        }
    }, [onDeviceConnected, onDeviceDisconnected, onDeviceStatus]);
}

export default useLiterBtDeviceEvents;