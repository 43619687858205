// @ts-nocheck
import React, { useCallback } from 'react';
import { PrimaryButton } from 'src/components/common/buttons/PrimaryButton';
import { useLanguage } from 'src/components/language/LanguageController';
import useHardwareDevice from 'src/hooks/hardwareIntegration/useHardwareDevice';

const HardwareDevice = ({
    deviceName,
    devicePath,
    setDevicePath,
    deviceBaudRate,
    setDeviceBaudRate,
    availableDevices = [],
    devicesStatus = {},
    deviceRawData = "",
    otherSelecteds = []
}) => {
    const { t } = useLanguage();
    const { connectDevice, disconnectDevice } = useHardwareDevice();

    const getOption = useCallback((device) => <option key={device.path} value={device.path}>{device.friendlyName ?? device.path}</option>, []);

    const renderDevices = useCallback(() => {
        return availableDevices
            .filter(device => !otherSelecteds.includes(device.path))
            .map(getOption);
    }, [availableDevices, getOption, otherSelecteds]);
    
    const renderDeviceStatus = useCallback(() => {
        return devicesStatus.isOpen ? 
        <span className="text-success">{t("text_connected")}</span>
        : <span className="text-danger">{t("text_disconnected")}</span>
    }, [devicesStatus.isOpen, t]);

    const renderDeviceError = useCallback(() => {
        return !devicesStatus?.isOpen && devicesStatus?.error?.message ? <p><strong>{t("text_error")}:</strong> {devicesStatus?.error.message}</p>  : "";
    }, [devicesStatus, t]);

    const renderDeviceButton = useCallback(() => {
        const isDevicePortPlugged = availableDevices.some(({ path }) => path === devicePath);
        return <>
            <PrimaryButton
                className="mr-2"
                disabled={devicesStatus?.isOpen || !isDevicePortPlugged}
                onClick={() => connectDevice(devicesStatus.deviceType)}
            >
                {t("text_connect")}
            </PrimaryButton>
            <PrimaryButton
                disabled={!devicesStatus?.isOpen || !isDevicePortPlugged}
                onClick={() => disconnectDevice(devicesStatus.deviceType)}
            >
                {t("text_disconnect")}
            </PrimaryButton>
        </>
    }, [availableDevices, connectDevice, devicePath, devicesStatus, disconnectDevice, t])

    return (
        <div>
            <h4 className="mb-3">{t(`text_${deviceName}`)}</h4>
            <div className="form-row">
                <div className="col col-lg-6">
                    <div className="form-group">
                        <label htmlFor={`${deviceName}_path`}>
                            {t(`text_select_${deviceName}_port`)}
                        </label>
                        <select
                            className="form-control"
                            name={`${deviceName}_path`}
                            id={`${deviceName}_path`}
                            value={devicePath}
                            onChange={(event) => setDevicePath(event.target.value)}
                        >
                            <option value="">{t("text_none")}</option>
                            {renderDevices()}
                        </select>
                    </div>
                </div>
                <div className="col col-lg-6">
                    <div className="form-group">
                        <label htmlFor={`${deviceName}_baud_rate`}>
                            {t(`text_${deviceName}_baud_rate`)}
                        </label>
                        <input type="number"
                            className="form-control"
                            name={`${deviceName}_baud_rate`}
                            id={`${deviceName}_baud_rate`}
                            value={deviceBaudRate}
                            onChange={(event) => setDeviceBaudRate(event.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div>
                <p>
                    <strong>{t("text_status")}: </strong> {renderDeviceStatus()}
                </p>
                {renderDeviceError()}
                <p>
                    <strong>{t("text_data")}:</strong><br/>
                    {deviceRawData}
                </p>
                {renderDeviceButton()}
            </div>
        </div>
    );
}

export default HardwareDevice;