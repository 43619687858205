// @ts-nocheck
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import ModalDialog from "src/components/common/ModalDialog/ModalDialog";
import MuiDatepicker from "src/components/common/MuiDatepicker";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import { useLanguage } from "src/components/language/LanguageController";
import { getShiftNumber } from "src/components/library/milkUtils";
import { fetchMilkCollection as fetchMilkCollectionDetail } from "src/offline-storage/dairy/milk-collection";
import ShiftSelector from "src/components/common/ShiftSelector";
import { useSelector } from "react-redux";
import { selectCustomersForDropDown } from "src/reducers/dairyCustomer";
import config from "src/config";
import { getDairyId } from "src/reducers/dairy";

const OfflineHistoryDialog = ({ isOpen, onClose }) => {
    const { t } = useLanguage();
    const [collectionRecords, setCollectionRecords] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment().subtract(1, "day"));
    const [shift, setShift] = useState(getShiftNumber());
    const customers = useSelector(selectCustomersForDropDown);
    const dairyId = useSelector(getDairyId);

    const fetchMilkCollection = useCallback(async (selectedDate, shift) => {
        const records = await fetchMilkCollectionDetail({
            date: selectedDate.format("YYYY-MM-DD"),
            shift,
            dairy_id: dairyId
        });
        setCollectionRecords(records);

    }, []);

    const onDateChange = useCallback((newDate) => {
        setSelectedDate(newDate)
    }, []);

    useEffect(() => {
        fetchMilkCollection(selectedDate, shift);
    }, [fetchMilkCollection, selectedDate, shift]);

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={'lg'}
            title={t("text_automatic_milk_collection_history")}
            dividers={false}
            content={
                <div className="automatic-milk-collection-container">
                    <div className="d-flex mb-2 justify-content-between">
                        <div className="flex-fill">
                            <ShiftSelector
                                shift={shift}
                                onShiftChange={(shift) => setShift(shift)}
                            />
                        </div>
                        <div>
                            <MuiDatepicker
                                value={selectedDate}
                                maxDate={moment()}
                                onChange={onDateChange}
                                name="date"
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="table_container table-responsive bg-light shadow-sm" >
                            <table className="table table-striped table-borderless mb-0" id="pdf-view">
                                <thead>
                                    <tr>
                                        <th>{t("text_customer")}</th>
                                        <th>{t("text_cattle")}</th>
                                        <th>{t("text_liter")}</th>
                                        <th>{t("text_fat")}</th>
                                        <th>{t("text_clr")}</th>
                                        <th>{t("text_snf")}</th>
                                        <th>{t("text_rate")}</th>
                                        <th>{t("text_amount")}</th>
                                        <th>{t("text_sync_status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {collectionRecords?.map(item => {
                                        const customer = customers.find(({ dairy_customer_id }) => Number(dairy_customer_id) === item.dairy_customer_id);
                                        if(!customer)
                                            return null;
                                        return (<tr key={`${item.dairy_customer_id}_${item.cattle}`}>
                                            <td>
                                                {`${customer.code} - ${customer.name}`}
                                            </td>
                                            <td>
                                                {t(`text_${item.cattle}`)}
                                            </td>
                                            <td>
                                                <span className="d-inline-block mr-2">
                                                    {item.liter}
                                                </span>
                                            </td>
                                            <td>
                                                {item.fat}
                                            </td>
                                            <td>
                                                {item.clr}
                                            </td>
                                            <td>
                                                {item.snf}
                                            </td>
                                            <td>{item.rate}</td>
                                            <td>
                                                {item.total}
                                            </td>
                                            <td>
                                                {Boolean(item.isSyncedUp) ? <><b>{t("text_last_synced")}:</b> <span>{moment(item.syncedUpTime).format(config.display_datetime_format)}</span></> : t("text_no")}
                                                
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            dialogActions={
                <>
                    <PrimaryButton
                        variant="contained"
                        size="small"
                        onClick={onClose}
                    >
                        {t("text_close")}
                    </PrimaryButton>
                </>
            }
        />
    );
};

export default OfflineHistoryDialog;