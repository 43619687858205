// @ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLanguage } from "src/components/language/LanguageController";
import { updateClrConfig, updateFatConfig, updateMilkWeightDensity, updateSnfConfig, updateTempConfig, updateWaterConfig, updateWeightDecimalPlaces } from "src/store/automation-settings/automation-settings";
import { selectMilkAnalyserConfig, selectMilkWeightDensity, selectWeightDecimalPlaces } from "src/store/automation-settings/selectors";

const OtherAutomationSetting = () => { 
    const { t } = useLanguage();
    const dispatch = useDispatch();

    const { fatConfig, snfConfig, clrConfig, tempConfig, waterConfig } = useSelector(selectMilkAnalyserConfig);
    const weightDecimalPlaces = useSelector(selectWeightDecimalPlaces);
    const milkWeightDensity = useSelector(selectMilkWeightDensity);
    
    return (
        <div className="row border-bottom pb-2 mb-3">
            <div className="col">
                <div className="form-row mb-2">
                    <div className="col">
                        <h4>Other Settings</h4>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-2">
                        <div className="form-row flex-column">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="weight_decimal_place">
                                        {t("text_weight_string_decimal_place")}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="weight_decimal_place"
                                        value={weightDecimalPlaces}
                                        onChange={e => dispatch(updateWeightDecimalPlaces(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="milk_weight_density">
                                        {t("text_milk_weight_density")}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="milk_weight_density"
                                        value={milkWeightDensity}
                                        onChange={e => dispatch(updateMilkWeightDensity(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-row">
                            <label className="text-center">{t("text_milk_analyzer")}</label>
                            <div className="row">
                                <div className="col">
                                    <div className="col-12 mb-2 ">
                                        <span className="form-row">
                                            <div className="col col-lg-6">
                                                <div className="form-group " >
                                                    <option id="clr_Config" className="form-control mb-1 text-center">CLR</option>
                                                    <option id="temp_Config" className="form-control mb-1 text-center">TEMP</option>
                                                    <option id="water_Config" className="form-control mb-1 text-center">WATER</option>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6" >
                                                <div className="form-group" >
                                                    <input id="clr_Config_input" value={clrConfig} onChange={e => dispatch(updateClrConfig(e.target.value))} className="form-control mb-1" type="number" min="0" />
                                                    <input id="temp_Config_input" value={tempConfig} onChange={e => dispatch(updateTempConfig(e.target.value))} className="form-control mb-1" type="number" min="0" />
                                                    <input id="water_Config_input" value={waterConfig} onChange={e => dispatch(updateWaterConfig(e.target.value))} className="form-control mb-1" type="number" min="0" />
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="col-12 mb-2 ">
                                        <span className="form-row">
                                            <div className="col col-lg-6">
                                                <div className="form-group " >
                                                    <option id="fat" className="form-control mb-1 text-center" >FAT</option>
                                                    <option id="snf_Config" className="form-control mb-1 text-center">SNF</option>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6" >
                                                <div className="form-group" >
                                                    <input id="fat_Config_input" value={fatConfig} onChange={e => dispatch(updateFatConfig(e.target.value))} className="form-control mb-1" type="number" min="0" />
                                                    <input id="snf_Config_input" value={snfConfig} onChange={e => dispatch(updateSnfConfig(e.target.value))} className="form-control mb-1" type="number" min="0" />
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OtherAutomationSetting;