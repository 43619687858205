import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  milkAnalyser: {
    isConnected : false,
    isOpen: false,
    error: "",
    deviceType: "milkAnalyser",
  },
  weightAnalyser: {
    isConnected : false,
    isOpen: false,
    error: "",
    deviceType: "weightAnalyser",
  },
}

export const hardwareDevicesSlice = createSlice({
  name: 'hardwareDevices',
  initialState,
  reducers: {
    updateMilkAnalyserStatus: (state, { payload }) => {
      state.milkAnalyser = payload;
    },
    updateWeightAnalyserStatus: (state, { payload }) => {
      state.weightAnalyser = payload;
    },
    updateDeviceStatus: (state, { payload }) => {
      return payload;
    }
  }
});

export const { updateMilkAnalyserStatus, updateWeightAnalyserStatus, updateDeviceStatus } = hardwareDevicesSlice.actions

export default hardwareDevicesSlice.reducer;