// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { BT_DEVICE_COMMANDS } from "src/_constants/autoCollection";
import Eventer from "src/components/library/Eventer";

const samplePorts = [
    {
        "path": "COM1",
        "friendlyName": "USB-SERIAL CH340 (COM1)",
    },
    {
        "path": "COM2",
        "friendlyName": "USB-SERIAL CH340 (COM2)",
    },
    {
        "path": "COM3",
        "friendlyName": "USB-SERIAL CH340 (COM3)",
    }
]

const defaultProps = {
    setUpEvent: false
}

const useHardwareDevice = ({ setUpEvent } = defaultProps) => {
    const [connectedPorts, setConnectedPorts] = useState(samplePorts);
    const [isPortLoading, setIsPortLoading] = useState(false);
    
    const dispatchGetMilk = useCallback(() => {
        window?.literAPI?.hwDevices?.dispatchGetMilk();
    }, []);

    const fetchDevicesStatus = useCallback(() => {
        console.log("fetch defice status")
        window.literAPI?.hwDevices?.getDevicesStatus();
    }, []);

    const connectDevice = (deviceType) => {
        window.literAPI?.hwDevices.connectDevice(deviceType);
    }

    const disconnectDevice = (deviceType) => {
        window?.literAPI?.hwDevices.disconnectDevice(deviceType);
    }

    const connectDevices = () => {
        window?.literAPI?.hwDevices.connectDevices();
    }

    const disconnectDevices = () => {
        window?.literAPI?.hwDevices.disconnectDevices();
    }

    const reloadSerialDevices = async () => {
        setIsPortLoading(true);
        window?.literAPI?.hwDevices?.getConnectedSerialDevices();
    }

    const onConnectedSerialDevices = ({ detail }) => {
        setIsPortLoading(false);
        setConnectedPorts(detail);
    }

    useEffect(() => {
        reloadSerialDevices();
    }, []);

    useEffect(() => {
        setUpEvent && Eventer.on("connectedDevices", onConnectedSerialDevices);
        return () => {
            setUpEvent && Eventer.off("connectedDevices", onConnectedSerialDevices);
        }
    }, [setUpEvent]);

    return {
        dispatchGetMilk,
        fetchDevicesStatus,
        connectDevice,
        disconnectDevice,
        connectDevices,
        disconnectDevices,
        isPortLoading,
        connectedPorts,
        reloadSerialDevices
    }
}

export default useHardwareDevice;