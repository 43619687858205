import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Eventer from "src/components/library/Eventer";
import { getShiftNumber } from "src/components/library/milkUtils";
import { selectIsCustomerSelected, selectSelectedCattle, selectSelectedCustomer } from "src/store/offline-store/milk-collection-customer/selectors";
import { addItem, updateItemAnalysis } from "src/store/offline-store/milk-collection/milk-collection";
import useAutomation from "../useAutomation";
import { deselectCustomer } from "src/store/offline-store/milk-collection-customer/milk-collection-customer";

const useAutoCollectionEvent = () => {
    const dispatch = useDispatch();
    const shift = getShiftNumber();
    const selectedCustomer = useSelector(selectSelectedCustomer);
    const selectedCattle = useSelector(selectSelectedCattle);
    const isCustomerSelected = useSelector(selectIsCustomerSelected);
    const { dispatchGetMilk } = useAutomation();
    const onLiterValue = useCallback(({ detail }) => {
        dispatch(addItem({ shift, liter: detail.value, selectedCustomer, selectedCattle }));
        dispatch(deselectCustomer());
    }, [dispatch, selectedCattle, selectedCustomer, shift]);

    const onMilkAnalysis = useCallback(({ detail }) => {
        console.log("onMilkAnalysis", detail);
        const milkAnalysis = detail;
        dispatch(updateItemAnalysis({ milkAnalysis }));
    }, [dispatch]);

    useEffect(() => {
        Eventer.on("ltrMilkAnalysis", onMilkAnalysis);
        Eventer.on("ltrLiter", onLiterValue);

        return () => {
            Eventer.off("ltrLiter", onLiterValue);
            Eventer.off("ltrMilkAnalysis", onMilkAnalysis);
        }
    }, [onLiterValue, onMilkAnalysis]);

    useEffect(() => {
        if(isCustomerSelected && selectedCustomer?.dairy_customer_id) {
            console.log("{ shift, selectedCustomer, selectedCattle }", { shift, selectedCustomer, selectedCattle });
            dispatchGetMilk();
        }
    }, [isCustomerSelected, selectedCustomer, selectedCattle, dispatch, shift, dispatchGetMilk]);
}

export default useAutoCollectionEvent;