// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customer: null,
  cattle: "",
  isSelected: false,
}

export const milkCollectionCustomerSlice = createSlice({
  name: 'milkCollection',
  initialState,
  reducers: {
    selectCustomer: (state, { payload }) => {
      state.customer = payload;
      state.cattle = payload.cattle
      state.isSelected = true;
    },
    deselectCustomer: (state, { payload }) => {
      state.customer = null;
      state.isSelected = false;
    }
  },
})

export const { selectCustomer, deselectCustomer } = milkCollectionCustomerSlice.actions

export default milkCollectionCustomerSlice.reducer;