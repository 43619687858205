import { createSelector } from "@reduxjs/toolkit";

export const selectLiterBtDeviceStore = (state) => state.literBtDevice;

export const selectDevice = createSelector(
    selectLiterBtDeviceStore,
    (state) => state.device
);

export const selectIsConnected = createSelector(
    selectLiterBtDeviceStore,
    (state) => state.isConnected
);

export const selectIsBtOn = createSelector(
    selectLiterBtDeviceStore,
    (state) => state.isBtOn
);

export const selectIsConnecting = createSelector(
    selectLiterBtDeviceStore,
    (state) => state.isConnecting
);
