import { createSelector } from "@reduxjs/toolkit";

export const selectAutomationSettingsStore = (state) => state.automationSettings;

export const selectAutomationSettings = createSelector(
    selectAutomationSettingsStore,
    (state) => state.settings
);

export const selectMilkAnalyserConfig = createSelector(
    selectAutomationSettings,
    (state) => ({
        fatConfig: state.fatConfig,
        snfConfig: state.snfConfig,
        clrConfig: state.clrConfig,
        tempConfig: state.tempConfig,
        waterConfig: state.waterConfig
    })
);

export const selectWeightAnalyser = createSelector(
    selectAutomationSettings,
    (state) => ({
        weightAnalyserPath: state.weightAnalyserPath,
        weightAnalyserBaudRate: state.weightAnalyserBaudRate
    })
);

export const selectMilkAnalyser = createSelector(
    selectAutomationSettings,
    (state) => ({
        milkAnalyserPath: state.milkAnalyserPath,
        milkAnalyserBaudRate: state.milkAnalyserBaudRate
    })
);

export const selectFatConfig = createSelector(
    selectAutomationSettings,
    (state) => state.fatConfig
);

export const selectSnfConfig = createSelector(
    selectAutomationSettings,
    (state) => state.snfConfig
);

export const selectClrConfig = createSelector(
    selectAutomationSettings,
    (state) => state.clrConfig
);

export const selectTempConfig = createSelector(
    selectAutomationSettings,
    (state) => state.tempConfig
);

export const selectWaterConfig = createSelector(
    selectAutomationSettings,
    (state) => state.waterConfig
);

export const selectWeightDecimalPlaces = createSelector(
    selectAutomationSettings,
    (state) => state.weightDecimalPlaces
);

export const selectHaveLiterDevice = createSelector(
    selectAutomationSettings,
    (state) => state.haveLiterDevice
);

export const selectMilkWeightDensity = createSelector(
    selectAutomationSettings,
    (state) => state.milkWeightDensity
);

export const selectIsAutoCollectionEnabled = createSelector(
    selectAutomationSettings,
    (state) => state.isAutoCollectionEnabled
);