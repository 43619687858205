import { createSelector } from "@reduxjs/toolkit";
import { selectOfflineStore } from "../selectors";

export const selectMilkCollectionCustomerState = createSelector(
    selectOfflineStore,
    (state) => state.milkCollectionCustomer
);

export const selectSelectedCustomer = createSelector(
    selectMilkCollectionCustomerState,
    (state) => state.customer
);

export const selectSelectedCattle = createSelector(
    selectMilkCollectionCustomerState,
    (state) => state.cattle
);

export const selectIsCustomerSelected = createSelector(
    selectMilkCollectionCustomerState,
    (state) => state.isSelected
);
