// @ts-nocheck
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AUTOMATIC_COLLECTION_SETTINGS } from "src/_constants";
import { getSetting, saveSetting } from "src/offline-storage/app/app-setting";
import { selectAutomationSettings } from "./selectors";

export const saveAutomationSettings = createAsyncThunk(
    'automationSettings/saveAutomationSettings',
    async (_, { getState }) => {
        const settings = selectAutomationSettings(getState());
        await saveSetting({
            key: AUTOMATIC_COLLECTION_SETTINGS,
            value: settings,
            type: "json"
        });
    },
);

export const fetchAutomationSettings = createAsyncThunk(
    'automationSettings/fetchAutomationSettings',
    async (_, { dispatch }) => {
        return await getSetting(AUTOMATIC_COLLECTION_SETTINGS);
    },
);