import { createSelector } from "@reduxjs/toolkit";
import { selectOfflineStore } from "../selectors";

export const selectMilkCollectionTotalState = createSelector(
    selectOfflineStore,
    (state) => state.milkCollectionTotal
);

export const selectMilkCollectionTotal = createSelector(
    selectMilkCollectionTotalState,
    (state) => state.data.data
);

export const selectMilkCollectionCount = createSelector(
    selectMilkCollectionTotalState,
    (state) => state.data.count
)