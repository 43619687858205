// @ts-nocheck
import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectDairyCustomers } from "src/reducers/dairyCustomer";
import { cloneDeep } from "lodash";
import { getDairyId } from "src/reducers/dairy";
import moment from "moment";
import {
  deleteMilkEntry,
  saveMilkCollection,
  fetchMilkCollection as fetchMilkCollectionDetails,
} from "src/offline-storage/dairy/milk-collection";
import { removeItem, updateItem } from "./milk-collection";
import { fetchMilkCollectionTotal } from "../milk-collection-total/thunk";

export const fetchMilkCollection = createAsyncThunk(
  "milkCollection/fetchMilkCollection",
  async ({ dairyId, shift, date }, { getState }) => {
    const queueData = await fetchMilkCollectionDetails({
      date,
      shift,
      dairy_id: dairyId,
    });
    const customers = selectDairyCustomers(getState());
    return queueData?.map((item) => {
      const customer = [...customers].find(
        ({ dairy_customer_id }) =>
          Number(dairy_customer_id) === Number(item.dairy_customer_id)
      );
      return {
        customer: cloneDeep(customer),
        analysis: item[`fat`] > 0,
        update: false,
        cattle: item.cattle,
        calculateRate: item[`fat`] <= 0,
        milk: {
          liter: item[`liter`],
          fat: item[`fat`],
          shift: item[`shift`],
          clr: item[`clr`],
          snf: item[`snf`],
          rate: item[`rate`],
          total: item[`total`],
        },
      };
    });
  }
);

export const saveMilkCollectionItem = createAsyncThunk(
  "milkCollection/MilkCollection",
  async (dataToSave, { getState, dispatch }) => {
    const state = getState();
    const dairyId = getDairyId(state);

    const date = moment().format("YYYY-MM-DD");
    const shift = dataToSave.shift;
    await saveMilkCollection({
      date,
      dairy_id: dairyId,
      ...dataToSave,
    });
    dispatch(updateItem(dataToSave));
    dispatch(
      fetchMilkCollectionTotal({
        date,
        shift,
        dairyId,
      })
    );
  }
);

export const deleteMilkCollectionItem = createAsyncThunk(
  "milkCollection/deleteMilkCollectionItem",
  async (
    { rowIndex, cattle, dairy_customer_id, shift },
    { getState, dispatch }
  ) => {
    const state = getState();
    const dairyId = getDairyId(state);
    const date = moment().format("YYYY-MM-DD");
    await deleteMilkEntry({
      date,
      shift,
      cattle,
      dairy_id: dairyId,
      dairy_customer_id: dairy_customer_id,
    });
    dispatch(
      removeItem({
        rowIndex,
      })
    );
    dispatch(
      fetchMilkCollectionTotal({
        date,
        shift,
        dairyId,
      })
    );
  }
);
