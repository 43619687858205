// @ts-nocheck
const { milkCollection } = window?.literAPI?.offlineDataStore || { milkCollection: () => {} };

export const saveMilkCollection = async (milkData) => {
    return milkCollection("saveMilk", milkData);
};

export const fetchMilkCollection = async (where) => {
    return await milkCollection("fetchQueueData", { where });
}

export const deleteMilkEntry = async (where) => {
    return await milkCollection("deleteMilkEntry", { where })
}

export const fetchCollectionTotal = async (where) => {
    return await milkCollection("getCollectionTotal", { where })
}