// @ts-nocheck
import { useLanguage } from "src/components/language/LanguageController";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import React from "react";
import LiterAutomationDevice from "../LiterAutomationDevice";
import AutoCollectionDevice from "../AutoCollectionDevice";
import HardwareDevicesSetting from "../HardwareDevicesSetting";
import OtherAutomationSetting from "../OtherAutomationSetting";
import { useDispatch, useSelector } from "react-redux";
import { selectHaveLiterDevice } from "src/store/automation-settings/selectors";
import { saveAutomationSettings } from "src/store/automation-settings/thunk";
import useAutomation from "src/hooks/useAutomation";
import { toast } from "src/components/library/util";

const MachinesStatus = () => {
    const { t } = useLanguage();
    const dispatch = useDispatch();

    const haveLiterDevice = useSelector(selectHaveLiterDevice);
    const { updateAutoCollectionConfig } = useAutomation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(saveAutomationSettings());
        toast(t("text_setting_saved"));
        updateAutoCollectionConfig();
    }

    return (
        
        <form encType="multipart/form-data" method="post" onSubmit={handleSubmit}>
            <AutoCollectionDevice />
            {haveLiterDevice && <LiterAutomationDevice />}
            {!haveLiterDevice && <HardwareDevicesSetting />}
            <OtherAutomationSetting />

            <div className="form-group">
                <PrimaryButton type="submit">
                    {t("text_save")}
                </PrimaryButton>
            </div>
        </form>
    )
}

export default MachinesStatus;