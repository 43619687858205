// @ts-nocheck
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelectBox from "src/components/core/ReactSelectBox";
import { t } from "src/components/language/LanguageController";
import { selectCustomersForDropDown } from "src/reducers/dairyCustomer";
import { selectCustomer } from "src/store/offline-store/milk-collection-customer/milk-collection-customer";
import { selectMilkCollection } from "src/store/offline-store/milk-collection/selectors";
import {
  filterSelectedCustomers,
  formatCustomersWithCattle,
  parseBarcode,
} from "src/utils/auto-collection-helper";
import _debounce from "lodash/debounce";
import { useHotkeys } from "react-hotkeys-hook";
import { selectIsCustomerSelected } from "src/store/offline-store/milk-collection-customer/selectors";

const CustomerSelection = () => {
  const dispatch = useDispatch();
  const customerSelectRef = useRef();
  const milkCollection = useSelector(selectMilkCollection);
  const isCustomerSelected = useSelector(selectIsCustomerSelected);
  const customers = useSelector(selectCustomersForDropDown)
    .filter((customer) => customer.type === "seller")
    .reduce(formatCustomersWithCattle, [])
    .filter(filterSelectedCustomers(milkCollection));

  const customerChangeHandler = useCallback(
    (customer) => {
      customer && dispatch(selectCustomer(customer));
    },
    [dispatch]
  );

  const onCustomerInputChange = useCallback(
    (searchText, actionMeta) => {
      if (
        searchText.toLowerCase().startsWith("ltr") &&
        searchText.length >= 12
      ) {
        const { code, cattle, alloweCode } = parseBarcode(searchText);
        if (alloweCode) {
          const customer = customers.find(
            (customer) =>
              Number(customer.code) === code &&
              customer.cattle === cattle.toLowerCase()
          );
          if (customer) {
            customerChangeHandler(customer);
          }
          customerSelectRef?.current?.blur();
          customerSelectRef?.current?.focus();
        }
      }
    },
    [customerChangeHandler, customers]
  );

  const onCustomerInputChangeDebounceFn = useCallback(
    _debounce(onCustomerInputChange, 50),
    [onCustomerInputChange]
  );

  useEffect(() => {
    !isCustomerSelected && customerSelectRef.current.clearValue();
  }, [isCustomerSelected]);

  useHotkeys("f1", () => customerSelectRef.current?.focus());

  return (
    <ReactSelectBox
      noOptionsMessage={() => t("text_no_options")}
      placeholder={t("text_select_customer") + "..."}
      options={customers}
      name="dairy_customer_id"
      inputId="input_dairy_customer_id"
      onChange={customerChangeHandler}
      onInputChange={onCustomerInputChangeDebounceFn}
      autoFocus
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      ref={customerSelectRef}
    />
  );
};

export default CustomerSelection;
