// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { fetchAutomationSettings, saveAutomationSettings } from "./thunk";

const initialState = {
  settings: {
    weightDecimalPlaces: 1,
    milkWeightDensity: 1.03,
    weightAnalyserPath: "",
    weightAnalyserBaudRate: 9600,
    milkAnalyserPath: "",
    milkAnalyserBaudRate: 9600,
    fatConfig: 0,
    snfConfig: 1,
    clrConfig: 2,
    tempConfig: 6,
    waterConfig: 7,
    haveLiterDevice: true,
    isAutoCollectionEnabled: true,
  },
  isSaving: false,
  isLoading: false,
  error: null,
};

export const automationSettingsSlice = createSlice({
  name: "automationSettings",
  initialState,
  reducers: {
    updateAutomationSettings: (state, { payload }) => {
      state.settings = payload;
    },
    updateWeightAnalyserPath: (state, { payload }) => {
      state.settings.weightAnalyserPath = payload;
    },
    updateWeightAnalyserBaudRate: (state, { payload }) => {
      state.settings.weightAnalyserBaudRate = payload;
    },
    updateMilkAnalyserPath: (state, { payload }) => {
      state.settings.milkAnalyserPath = payload;
    },
    updateMilkAnalyserBaudRate: (state, { payload }) => {
      state.settings.milkAnalyserBaudRate = payload;
    },
    updateFatConfig: (state, { payload }) => {
      state.settings.fatConfig = payload;
    },
    updateSnfConfig: (state, { payload }) => {
      state.settings.snfConfig = payload;
    },
    updateClrConfig: (state, { payload }) => {
      state.settings.clrConfig = payload;
    },
    updateTempConfig: (state, { payload }) => {
      state.settings.tempConfig = payload;
    },
    updateWaterConfig: (state, { payload }) => {
      state.settings.waterConfig = payload;
    },
    updateWeightDecimalPlaces: (state, { payload }) => {
      state.settings.weightDecimalPlaces = payload;
    },
    updateHaveLiterDevice: (state, { payload }) => {
      state.settings.haveLiterDevice = payload;
    },
    updateMilkWeightDensity: (state, { payload }) => {
      state.settings.milkWeightDensity = payload;
    },
    updateIsAutoCollectionEnabled: (state, { payload }) => {
      state.settings.isAutoCollectionEnabled = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAutomationSettings.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchAutomationSettings.fulfilled, (state, action) => {
      console.log("fetchAutomationSettings.fulfilled", action.payload);
      state.isLoading = false;
      state.settings = {
        ...state.settings,
        ...action.payload,
      };
      state.error = null;
    });

    builder.addCase(fetchAutomationSettings.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message || null;
    });

    builder.addCase(saveAutomationSettings.pending, (state, action) => {
      state.isSaving = true;
      state.error = null;
    });

    builder.addCase(saveAutomationSettings.fulfilled, (state, action) => {
      console.log("saveAutomationSettings.fulfilled", action.payload);
      state.isSaving = false;
      state.error = null;
    });

    builder.addCase(saveAutomationSettings.rejected, (state, action) => {
      state.isSaving = false;
      state.error = action.payload?.message || null;
    });
  },
});

export const {
  updateAutomationSettings,
  updateWeightAnalyserPath,
  updateWeightAnalyserBaudRate,
  updateMilkAnalyserPath,
  updateMilkAnalyserBaudRate,
  updateFatConfig,
  updateClrConfig,
  updateSnfConfig,
  updateTempConfig,
  updateWaterConfig,
  updateWeightDecimalPlaces,
  updateHaveLiterDevice,
  updateMilkWeightDensity,
  updateIsAutoCollectionEnabled,
} = automationSettingsSlice.actions;

export default automationSettingsSlice.reducer;
