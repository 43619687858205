import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Eventer from "src/components/library/Eventer";
import { updateDeviceStatus } from "src/store/hardware-devices/hardware-devices";

const useHardwareDeviceEvents = () => {
    const dispatch = useDispatch();
    const onDevicesStatus = useCallback(({ detail }) => {
        dispatch(updateDeviceStatus(detail));
    }, [dispatch]);

    useEffect(() => {
        Eventer.on("devicesStatus", onDevicesStatus);
        return () => {
            Eventer.off("devicesStatus", onDevicesStatus);
        }
    }, [onDevicesStatus]);
}

export default useHardwareDeviceEvents;