// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { useLanguage } from "src/components/language/LanguageController";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomersForDropDown } from "src/reducers/dairyCustomer";
import CollectionQueueRow from "./CollectionQueueRow/CollectionQueueRow";
import moment from "moment";
import { getShiftNumber } from "src/components/library/milkUtils";
import { getDairyId } from "src/reducers/dairy";
import ReactSelectBox from "src/components/core/ReactSelectBox";
import { fetchMilkCollectionTotal } from "src/store/offline-store/milk-collection-total/thunk";
import CollectionTotal from "./CollectionTotal";
import AutoCollectionHeader from "./AutoCollectionHeader/AutoCollectionHeader";
import { Redirect } from "react-router-dom";
import { formatCustomersWithCattle, filterSelectedCustomers } from "../../../../../utils/auto-collection-helper";
import { fetchAutomationSettings } from "src/store/automation-settings/thunk";
import useAutomation from "src/hooks/useAutomation";
import { fetchMilkCollection } from "src/store/offline-store/milk-collection/thunk";
import { selectMilkCollection } from "src/store/offline-store/milk-collection/selectors";
import { selectCustomer } from "src/store/offline-store/milk-collection-customer/milk-collection-customer";
import { selectIsCustomerSelected } from "src/store/offline-store/milk-collection-customer/selectors";
import { selectIsAutoCollectionEnabled } from "src/store/automation-settings/selectors";
import CustomerSelection from "./CustomerSelection";

const AutoMilkCollection = () => {
    const shift = getShiftNumber();
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const dairyId = useSelector(getDairyId);
    const isCustomerSelected = useSelector(selectIsCustomerSelected);
    const collectionQueue = useSelector(selectMilkCollection);
    const isAutoCollectionEnabled = useSelector(selectIsAutoCollectionEnabled);
    const  { fetchDevicesStatus } = useAutomation();

    const fetchCollectionDetails = useCallback(() => {
        dispatch(fetchMilkCollectionTotal({
            date: moment().format("YYYY-MM-DD"),
            shift,
            dairyId
        }));
        dispatch(fetchMilkCollection({
            date: moment().format("YYYY-MM-DD"),
            shift,
            dairyId
        }));
    }, [dairyId, dispatch, shift]);

    const keyDownHandler = (e, rowIndex, cellIndex) => {
        let nextElement;
        if (e.key === 'ArrowDown') {
            nextElement = document.getElementById(`cell-${rowIndex + 1}-${cellIndex}`);
        } else if (e.key === 'ArrowUp') {
            nextElement = document.getElementById(`cell-${rowIndex - 1}-${cellIndex}`);
        } else if (e.key === 'ArrowRight') {
            nextElement = document.getElementById(`cell-${rowIndex}-${cellIndex + 1}`);
        } else if (e.key === 'ArrowLeft') {
            nextElement = document.getElementById(`cell-${rowIndex}-${cellIndex - 1}`);
        }
        if(rowIndex === 0 && e.key === 'ArrowUp') {
            document.getElementById('input_dairy_customer_id').focus();
        } else if (nextElement) {
            const inputElement = nextElement.querySelector('input');
            const buttonElement = nextElement.querySelector('button');
            if (inputElement && !inputElement.disabled) {
                inputElement.focus();
            } else if (buttonElement && !buttonElement.disabled) {
                buttonElement.focus();
            } else {
                nextElement.focus();
            }
        }
    };

    useEffect(() => {
        fetchCollectionDetails();
    }, [fetchCollectionDetails]);

    useEffect(() => {
        dispatch(fetchAutomationSettings());
        fetchDevicesStatus();
    }, [dispatch]);

    if(!window?.literAPI?.desktop) {
        return <Redirect to="/" />
    }

    return (

        <DefaultLayout
            title={t("text_auto_milk_collection")}
            toolbar={false}
            back
        >   
            <div data-auto="auto-milk-collection-container">
                <AutoCollectionHeader />
                <CollectionTotal />
                <div className="shadow-sm">
                    <table className="table table-bordered table-green-header table-light font-size-85r">
                        <thead>
                            <tr>
                                <th className="p-2 width25p">{t("text_customer")}</th>
                                <th className="p-2 width5p">{t("text_cattle")}</th>
                                <th className="p-2 width13p">{t("text_liter")}</th>
                                <th className="p-2 width10p">{t("text_fat")}</th>
                                <th className="p-2 width10p">{t("text_clr")}</th>
                                <th className="p-2 width10p">{t("text_snf")}</th>
                                <th className="p-2 width10p">{t("text_rate")}</th>
                                <th className="p-2 width10p">{t("text_amount")}</th>
                                <th className="text-center p-2 width5p">{t("text_action")}</th>
                            </tr>
                        </thead>
                        <tbody>                            
                            <tr>
                                <td className="p-0">
                                    <CustomerSelection />
                                </td>
                                <td className="p-0">
                                </td>
                                <td colSpan={7}></td>
                            </tr>
                            {collectionQueue.map((item, rowIndex) => {
                                if(!item.customer) {
                                    return null;
                                }
                                return (
                                    <CollectionQueueRow
                                        key={`${item.customer.dairy_customer_id}_${item.cattle}`}
                                        rowIndex={rowIndex}
                                        queueItem={{...item}}
                                        shift={shift}
                                        onSave={fetchCollectionDetails}
                                        onDelete={fetchCollectionDetails}
                                        autoFocus={!isAutoCollectionEnabled && 0 === rowIndex}
                                        keyDownHandler={keyDownHandler}
                                        autoCollectionSwitch={isAutoCollectionEnabled}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default AutoMilkCollection;
