// @ts-nocheck
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateIsConnecting } from "src/store/liter-bt-device/liter-bt-device";

const useLiterBtDevice = () => {
  const dispatch = useDispatch();

  const connectDevices = useCallback(() => {
    dispatch(updateIsConnecting(true));
    window?.literAPI?.bluetooth?.connectDevice();
  }, [dispatch]);

  const disconnectDevices = useCallback(() => {
    console.log("disconnectDevice");
    dispatch(updateIsConnecting(true));
    window?.literAPI?.bluetooth?.diconnectDevice();
  }, [dispatch]);

  const dispatchGetMilk = useCallback(() => {
    window?.literAPI?.bluetooth?.dispatchGetMilk();
  }, []);

  const fetchDevicesStatus = useCallback(() => {
    window?.literAPI?.bluetooth?.getDeviceStatus();
  }, []);

  const syncConfiguration = () => {
    window?.literAPI?.bluetooth?.syncConfiguration();
  };

  return {
    connectDevices,
    disconnectDevices,
    dispatchGetMilk,
    fetchDevicesStatus,
    syncConfiguration,
  };
};

export default useLiterBtDevice;
