import { fetchSettings } from 'src/actions/dairySettings';
import { useDispatch } from 'react-redux';
const { useEffect } = require("react");

const useAutomaticSetting = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
}

export default useAutomaticSetting;